import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer id='footer'>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 footer-info">
                            <img src={"https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Loghi%2FlogoFooter-removebg.png?alt=media&token=9e1e761d-05f3-4490-be60-7c201cb043d0"} alt="WorkSpace Logo" style={{ width: '70%', height: 'auto' }} />
                        </div>
                        <div className="col-lg-2 col-md-6 footer-links">
                            <h4>Pagine</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/about">Chi siamo</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/spettacoli">Spettacoli</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/eventiculturali">Eventi Culturali</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/sinergie">Sinergie</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>I nostri Spettacoli</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/spettacolo/7">Tre Pecore Viziose</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/spettacolo/6">Non sparate su postino</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/spettacolo/5">I papà nascono negli armadi</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/spettacolo/4">Caviale e lenticchie</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/spettacolo/3">Dio c'è ma non si vede</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-newsletter">
                            <p>
                                Il Centro, via Conciliazione 15 <br />
                                20832 Desio (MB)<br /><br />
                                <strong>Telefono:</strong> +39 351.8364993<br />
                                <strong>Email:</strong> ideeinscenadesio@gmail.com<br />
                            </p>
                            <div className="social-links mt-3">
                                <a 
                                    href="https://www.facebook.com/ideeinscena" 
                                    className="facebook" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-facebook"></i>
                                </a>
                                <a 
                                    href="https://www.instagram.com/_ideeinscena_/?hl=it" 
                                    className="instagram" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-instagram"></i>
                                </a>
                                <a 
                                    href="https://www.youtube.com/@ideeinscenacompagniateatra2665" 
                                    className="youtube" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-youtube"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>IdeeInScena</span></strong>. All Rights Reserved
                </div>
                <div className="credits">
                    <Link to="/policy">Privacy Policy</Link>
                </div>
                <div className="credits">
                    Designed by Giacomo Barzaghi
                </div>
            </div>
        </footer>
    );
}

export default Footer;
