import React, { useEffect } from "react";
import { useMediaQuery } from 'react-responsive';

import Navbar from '../Components/Navbar';
import Intestazione from "../Components/Intestazione";
import Footer from "../Components/Footer";

const Sinergie = () => {
    useEffect(() => {
        document.title = 'Sinergie';
    }, []);

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' }); // Controllo per mobile

    // Stili dinamici per il titolo e il testo
    const titleStyle = {
        fontSize: isMobile ? '36px' : '24px',
        lineHeight: isMobile ? '1.4' : '1.2',
    };

    const textStyle = {
        fontSize: isMobile ? '20px' : '16px',
        lineHeight: isMobile ? '1.6' : '1.4',
    };

    return (
        <div>
            <Navbar />
            <header id="header">
                <Intestazione
                    titolo={'SINERGIE'}
                    sottotitolo={'IDEEINSCENA'}
                    linkAbilitato={false}
                />
            </header>
            <main className="main-content">
                <div className="row">
                    {/* Prima colonna: Testo */}
                    <div className="col-12 col-md-6">
                        <h3 style={titleStyle}>Sala Don Caccia Giussano</h3>
                        <br />
                        <p style={textStyle}>
                            Grazie alla sinergia con la compagnia Amici del teatro di Birone di Giussano, Ideeinscena contribuisce all’organizzazione della rassegna di teatro amatoriale che viene ospitata nella Sala Don Caccia di Giussano. Alla rassegna vengono invitate a partecipare alcune tra le migliori filodrammatiche, provenienti sia dalla Lombardia sia da altre regioni. La prima edizione della rassegna è datata 1987, organizzata dalla Parrocchia Santo Stefano di Birone di Giussano. Negli anni sono state ospitate oltre quaranta compagnie di teatro amatoriale, sia dialettale che in lingua. La manifestazione è sempre stata un punto di riferimento per il territorio brianzolo. Tra i principi ispiratori spiccano la diffusione della cultura e della passione per il teatro ma anche il rispetto per la tradizione cristiana e per la diffusione dei suoi valori. Dopo il periodo pandemico, nel 2023 è nata la sinergia tra Ideeinscena e gli Amici del teatro.
                        </p>
                    </div>

                    <div className="col-12 col-md-6">
                        <img
                            src={'https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Locandine%2FGiussano24_25.jpg?alt=media&token=f7bf5ce9-9890-47d6-a9d6-3d6a2aebeed6'}
                            className="img-fluid imgSinergie"
                            alt="Locandina Giussano 2024-2025"
                            style={{ width: '60%', marginBottom: isMobile ? '15px' : '0' }}
                        />
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Sinergie;
