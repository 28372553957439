import React from 'react';
import { Link } from 'react-router-dom';

const EventoCard = ({ imgSrc, Titolo, Autore, Data, content, link }) => {
    return (
        <article className="entry">
            <div className="entry-img">
                {imgSrc && <img src={imgSrc} alt={Titolo} className="img-fluid" />}
            </div>
            <h2 className="entry-title">
                <Link to={link}>{Titolo}</Link>
            </h2>
            <div className="entry-meta">
                <ul>
                    <li className="d-flex align-items-center">
                        <i className="bi bi-person"></i>
                        {Autore}
                    </li>
                    <li className="d-flex align-items-center">
                        <i className="bi bi-clock"></i>
                        <time dateTime={Data}>{Data}</time>
                    </li>
                </ul>
            </div>
            <div className="entry-content">
                <p>{content}</p>
                <div className="read-more">
                    <Link to={link}>Leggi di più</Link>
                </div>
            </div>
        </article>
    );
};

export default EventoCard;
