import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";

const ImageGallery = ({ title, testo, imageLinks }) => {
    const [modalIndex, setModalIndex] = useState(null);
    const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });

    const titleStyle = {
        fontSize: isTablet ? "40px" : "24px",
        textAlign: "center",
        marginBottom: "20px",
    };

    const textStyle = {
        fontSize: isTablet ? "30px" : "16px",
        textAlign: "center",
        marginBottom: "20px",
    };

    const openModal = (index) => {
        setModalIndex(index);
    };

    const closeModal = () => {
        setModalIndex(null);
    };

    const handleNext = () => {
        setModalIndex((prevIndex) => (prevIndex + 1) % imageLinks.length);
    };

    const handlePrev = () => {
        setModalIndex((prevIndex) =>
            (prevIndex - 1 + imageLinks.length) % imageLinks.length
        );
    };

    return (
        <div className="container custom-box-no-bg">
            <h2 style={titleStyle}>{title}</h2>
            <p style={textStyle}>{testo}</p>
            <div className="row">
                {imageLinks.map((link, index) => (
                    <div className="col-md-4 mb-4" key={index}>
                        <img
                            src={link}
                            className="img-fluid"
                            alt={`Image ${index}`}
                            onClick={() => openModal(index)}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                ))}
            </div>

            {modalIndex !== null && (
                <div
                    className="modal d-block"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="imageModalLabel"
                    aria-hidden="true"
                    onClick={closeModal}
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-lg"
                        role="document"
                    >
                        <div
                            className="modal-content"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="modal-body d-flex justify-content-center align-items-center position-relative">
                                <BsChevronLeft
                                    className="position-absolute"
                                    style={{
                                        left: "10px",
                                        top: "50%",
                                        cursor: "pointer",
                                        zIndex: 1000,
                                        fontSize: "3rem",
                                        color: "white",
                                    }}
                                    onClick={handlePrev}
                                />
                                <img
                                    src={imageLinks[modalIndex]}
                                    className="img-fluid"
                                    alt="Modal Content"
                                    style={{ maxHeight: "80vh", maxWidth: "80vw" }}
                                />
                                <BsChevronRight
                                    className="position-absolute"
                                    style={{
                                        right: "10px",
                                        top: "50%",
                                        cursor: "pointer",
                                        zIndex: 1000,
                                        fontSize: "3rem",
                                        color: "white",
                                    }}
                                    onClick={handleNext}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageGallery;
