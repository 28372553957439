import React, { useEffect } from 'react';

const Loading = () => {
    useEffect(() => {
        document.title = 'Loading....'
    }, [])

    return (
        <div className="loading-container">
            <div className="loading-spinner"></div>
        </div>
    );
};

export default Loading;
