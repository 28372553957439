import React, { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebaseConfig';

import Navbar from '../Components/Navbar';
import SpettacoloSidebar from '../Components/SpettacoloSidebar';
import Loading from "../Components/Loading";
import ImageGallery from '../Components/ImageGallery';
import BackupButton from "../Components/BackupButton";
import Footer from "../Components/Footer";

const Spettacolo = () => {
    const { id } = useParams();
    const navigate = useNavigate(); // Usa useNavigate
    const [spettacolo, setSpettacolo] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);

    useEffect(() => {
        const fetchSpettacolo = async () => {
            try {
                const docRef = doc(db, 'Spettacoli', id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    
                    console.log(data.isBozza)
                    if (data.isBozza === true) {
                        navigate('/nondisponibile');
                        return; 
                    }

                    setSpettacolo(data);

                    if (data.FolderPath) {
                        const folderRef = ref(storage, data.FolderPath);
                        const res = await listAll(folderRef);
                        const urls = await Promise.all(
                            res.items.map(async (itemRef) => {
                                const url = await getDownloadURL(itemRef);
                                return url;
                            })
                        );
                        setImageUrls(urls);
                    }
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching document:", error);
            }
        };

        fetchSpettacolo();
    }, [id, navigate]);

    useEffect(() => {
        if (spettacolo) {
            document.title = spettacolo.Titolo;
        }
    }, [spettacolo]);

    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    if (!spettacolo) {
        return <Loading />;
    }

    return (
        <div>
            <Navbar />
            <main id="main">
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>{spettacolo.Titolo}</h2>
                        </div>
                    </div>
                </section>

                <section id="blog" className="blog">
                    <div className="container" data-aos="fade-up">
                        <div className="row">
                            <div className="col-lg-8 entries">
                                <article className="entry entry-single">
                                    <div className="entry-img">
                                        <img
                                            src={spettacolo.urlFotoGruppo}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <div className="entry-meta">
                                        <ul>
                                            <li className="d-flex align-items-center">
                                                <i className="bi bi-person"></i>
                                                <a href="blog-single.html">{spettacolo.Autore}</a>
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <i className="bi bi-clock"></i>
                                                <a href="blog-single.html">
                                                    <time dateTime="2020-01-01">{spettacolo.Data}</time>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="entry-content">
                                        <br />
                                        <p>{spettacolo.Testo1}</p>
                                        <blockquote>
                                            <p>
                                                {spettacolo.Citazione}
                                            </p>
                                        </blockquote>
                                        {spettacolo.LinkYTB ? (
                                            <iframe
                                                width="560"
                                                height="315"
                                                src={spettacolo.LinkYTB}
                                                title="YouTube video player"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                referrerPolicy="strict-origin-when-cross-origin"
                                                allowFullScreen
                                            ></iframe>
                                        ) : null}
                                        <br /><br />
                                        <h3>{spettacolo.Sottotitolo}</h3>
                                        <p>{spettacolo.Testo2}</p>
                                    </div>
                                </article>
                                <div className="blog-author d-flex align-items-center">
                                    <div>
                                        <h4>{spettacolo.Autore}</h4>
                                        <div className="social-links">
                                            <a href="https://www.youtube.com/@ideeinscenacompagniateatra2665"><i className="bi bi-youtube"></i></a>
                                            <a href="https://www.facebook.com/ideeinscena"><i className="bi bi-facebook"></i></a>
                                            <a href="https://www.instagram.com/_ideeinscena_/?hl=it"><i className="bi bi-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                {!isTablet &&
                                    <div id="sidebar" className="sidebar">
                                        <SpettacoloSidebar />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container pers" data-aos="fade-up">
                    <div className="section-title">
                        <h2>{spettacolo.Titolo}</h2>
                        <p>GALLERIA FOTOGRAFICA</p>
                    </div>
                </div>

                <div id="gallerySection" className="container">
                    <ImageGallery title="" imageLinks={imageUrls} />
                </div>
            </main>
            <BackupButton />
            <Footer />
        </div>
    );
}

export default Spettacolo;
