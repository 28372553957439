import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Button from './Button';
import { Link } from 'react-router-dom';

const CarouselComponent = () => {
    const [spettacoli, setSpettacoli] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const spettacoliCardCol = collection(db, 'SpettacoliCard');
                const spettacoliCardQuery = query(spettacoliCardCol, where('isBozza', '==', false));
                const spettacoliCardSnapshot = await getDocs(spettacoliCardQuery);

                const spettacoliCardList = spettacoliCardSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setSpettacoli(spettacoliCardList);
                setIsDataLoaded(true);
            } catch (error) {
                console.error('Errore nel recupero degli spettacoli:', error);
            }
        };

        fetchData();
    }, []);

    // Definisci gli stili per la versione desktop, tablet e mobile
    const slideStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
    };

    const imgStyle = {
        maxWidth: isMobile ? '70vw' : isTablet ? '40vw' : '20vw', // Ingrandisci le immagini per mobile e tablet
        marginRight: isMobile ? '0' : '20px', // Rimuovi il margine in mobile per centrare meglio l'immagine
        marginBottom: isMobile ? '20px' : '0', // Aggiungi margine in basso in mobile per separare testo e immagine
    };

    const textStyle = {
        flex: 1,
    };

    const titleStyle = {
        margin: 0,
        fontSize: isMobile ? '1.8em' : '1.5em', // Aumenta il font del titolo in mobile
    };

    const textParagraphStyle = {
        margin: '0.5em 0 0',
        fontSize: isMobile ? '1.5em' : isTablet ? '1.2em' : '1em', // Aumenta il font del testo in mobile e tablet
        lineHeight: '1.6', // Migliora la leggibilità del testo
    };

    const cardStyle = {
        backgroundColor: 'white',
        padding: isMobile ? '30px' : '20px', // Aumenta il padding per mobile
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row', // Cambia la direzione della card in mobile
        alignItems: 'center',
        width: isMobile ? '90vw' : isTablet ? '70vw' : '50vw', // Ingrandisci le card per mobile e tablet
        textAlign: isMobile ? 'center' : 'left', // Allinea il testo al centro in mobile
    };

    const indicatorStyle = {
        background: 'lightgrey',
        width: 12,
        height: 12,
        borderRadius: '50%',
        display: 'inline-block',
        margin: '0 8px'
    };

    const arrowStyle = {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        zIndex: 2,
        color: 'orange',
        fontSize: '40px',
    };

    return (
        <>
            <ReactCarousel
                autoPlay={isDataLoaded}
                interval={5000}
                infiniteLoop={true}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                    if (isSelected) {
                        return (
                            <li
                                style={{ ...indicatorStyle, background: 'orange' }}
                                aria-label={`Selected: ${label} ${index + 1}`}
                                title={`Selected: ${label} ${index + 1}`}
                            />
                        );
                    }
                    return (
                        <li
                            style={indicatorStyle}
                            onClick={onClickHandler}
                            onKeyDown={onClickHandler}
                            value={index}
                            key={index}
                            role="button"
                            tabIndex={0}
                            title={`${label} ${index + 1}`}
                            aria-label={`${label} ${index + 1}`}
                        />
                    );
                }}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && !isTablet && (
                        <div
                            style={{ ...arrowStyle, left: '300px' }}
                            onClick={onClickHandler}
                            title={label}
                        >
                            &lt;
                        </div>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && !isTablet && (
                        <div
                            style={{ ...arrowStyle, right: '300px' }}
                            onClick={onClickHandler}
                            title={label}
                        >
                            &gt;
                        </div>
                    )
                }
            >
                {spettacoli.map((spettacolo, index) => (
                    <div key={index} style={slideStyle}>
                        <div style={cardStyle}>
                            <img src={spettacolo.imgurl} alt={spettacolo.Titolo} style={imgStyle} />
                            <div style={textStyle}>
                                <h3 style={titleStyle}>{spettacolo.Titolo}</h3>
                                <p style={textParagraphStyle}>{spettacolo.Testo}</p>

                                <Button
                                    linkAbilitato={true}
                                    testoBottone={'Vai allo spettacolo'}
                                    linkTo={`/spettacolo/${spettacolo.id}`}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </ReactCarousel>

            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px' }}>
                {spettacoli.map((spettacolo, index) => (
                    <Link
                        key={index}
                        to={`/spettacolo/${spettacolo.id}`}
                        style={{ margin: '0 5px' }}
                    >
                        <img
                            src={spettacolo.imgurl}
                            alt={spettacolo.Titolo}
                            style={{ width: '6vw', height: 'auto', objectFit: 'cover' }}
                        />
                    </Link>
                ))}
            </div>
        </>
    );
};

export default CarouselComponent;
