import React from "react";
import { useMediaQuery } from 'react-responsive';
import Button from "./Button";

const Container = ({ classname, imgSrc, titolo, testo, testoSecondario, linkAbilitato, testoBottone, navigateTo }) => {
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    const titleFontSize = isTablet ? '40px' : '24px'; 
    const textStyle = {
        fontSize: isTablet ? '30px' : '16px',
    };

    return (
        <div className={classname}>
            <h2 className="text-center mb-4" style={{ fontSize: titleFontSize }}>{titolo}</h2>
            <div className="row align-items-center">
                {isTablet ? (
                    <>
                        <div className="col-12 d-flex justify-content-center">
                            <img src={imgSrc} className="img-fluid mb-6" alt={titolo} />
                        </div>
                        <div className="col-12">
                            <p style={textStyle}>{testo}</p>
                            <p style={{ ...textStyle, marginTop: '20px' }}>{testoSecondario}</p>
                            <Button 
                                linkAbilitato={linkAbilitato} 
                                testoBottone={testoBottone} 
                                linkTo={navigateTo} 
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-md-6 d-flex justify-content-center align-items-center">
                            <img src={imgSrc} className="img-fluid" alt={titolo} />
                        </div>
                        <div className="col-md-6">
                            <p style={textStyle}>{testo}</p>
                            <p style={{ ...textStyle, marginTop: '20px' }}>{testoSecondario}</p>
                            <Button 
                                linkAbilitato={linkAbilitato} 
                                testoBottone={testoBottone} 
                                linkTo={navigateTo} 
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Container;
