import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const BackupButton = () => {
    useEffect(() => {
        const handleScroll = () => {
            const backToTopButton = document.querySelector('.back-to-top');
            
            // Mostra il pulsante se si è scorsi più di 100px, altrimenti nascondilo
            if (window.scrollY > 100) {
                backToTopButton.classList.add('active');
            } else {
                backToTopButton.classList.remove('active');
            }
        };

        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleClick = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Link 
            to="#" 
            className="back-to-top d-flex align-items-center justify-content-center"
            onClick={handleClick}
        >
            <i className="bi bi-arrow-up-short"></i>
        </Link>
    );
};

export default BackupButton;
