import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const Navbar = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const fontSizeClass = isMobile ? 'font-size-mobile' : 'font-size-desktop';
    const togglerIconSizeClass = isMobile ? 'toggler-icon-mobile' : 'toggler-icon-desktop';

    return (
        <nav id="navbar" className="navbar navbar-expand-lg navbar-dark custom-navbar fixed-top">
            <div className="container">
                <Link className="navbar-brand" to="/">IdeeInScena</Link>
                <button
                    className={`navbar-toggler ${togglerIconSizeClass}`}
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link className={`nav-link ${fontSizeClass}`} to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${fontSizeClass}`} to="/about">Chi Siamo</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link
                                className={`nav-link dropdown-toggle ${fontSizeClass}`}
                                to="#"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Eventi
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <Link className={`dropdown-item ${fontSizeClass}`} to="/spettacoli">Spettacoli</Link>
                                <Link className={`dropdown-item ${fontSizeClass}`} to="/eventiculturali">Eventi Culturali</Link>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${fontSizeClass}`} to="/sinergie">Sinergie</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${fontSizeClass}`} to="/sostienici">Sostienici</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${fontSizeClass}`} to="/contatti">Contattaci</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
