import React from "react";
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

const Button = ({ linkAbilitato, testoBottone, linkTo }) => {
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    // Cambia la dimensione del font in base alla dimensione dello schermo
    const buttonFontSize = isTablet ? '30px' : '16px'; 

    if (!linkAbilitato) {
        return null;
    }

    return (
        <Link to={linkTo} className="btn-learn-more" style={{ fontSize: buttonFontSize }}>
            {testoBottone}
        </Link>
    );
};

export default Button;
