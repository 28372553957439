import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import Button from './Button';

const MainSidebar = () => {
    const [eventData, setEventData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const db = getFirestore();
            const docRef = doc(db, 'Elementi', 'SidebarHome');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setEventData(docSnap.data());
            } else {
                console.error('Documento non trovato!');
            }
        };

        fetchData();
    }, []);

    if (!eventData) {
        return <div>Caricamento...</div>;
    }

    return (
        <div className="position-sticky" style={{ top: '2rem' }}>
            <h4>Ultimi Eventi</h4>
            <ul className="list-unstyled">
                <li>
                    <Link
                        className="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center py-3 link-body-emphasis text-decoration-none border-top"
                        to={`./spettacolo/${eventData.IdSpettacolo}`}>
                        <img
                            src={eventData.Loc1}
                            alt={eventData.Titolo1}
                            className="img-fluid sidebar-img"
                        />
                        <div className="col-lg-8">
                            <h6 className="mb-0">{eventData.Titolo1}</h6>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        className="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center py-3 link-body-emphasis text-decoration-none border-top"
                        to={`./evento/${eventData.IdEvento}`}>
                        <img
                            src={eventData.Loc2}
                            alt={eventData.Titolo2}
                            className="img-fluid sidebar-img"
                        />
                        <div className="col-lg-8">
                            <h6 className="mb-0">{eventData.Titolo2}</h6>
                        </div>
                    </Link>
                </li>
            </ul>

            <h4>Rassegne</h4>
            <ul className="list-unstyled">
                <li>
                    <Link
                        className="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center py-3 link-body-emphasis text-decoration-none border-top"
                        to="/sinergie">
                        <img
                            src={eventData.Loc3}
                            alt={eventData.Titolo3}
                            className="img-fluid sidebar-img"
                        />
                        <div className="col-lg-8">
                            <h6 className="mb-0">{eventData.Titolo3}</h6>
                        </div>
                    </Link>
                </li>
            </ul>

            <h4>Puoi trovarci anche su:</h4>
            <ol className="list-unstyled d-flex gap-3 social-icons">
                <li>
                    <a href="https://www.facebook.com/ideeinscena" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook fa-2x"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/_ideeinscena_/?hl=it" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram fa-2x"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/@ideeinscenacompagniateatra2665" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-youtube fa-2x"></i>
                    </a>
                </li>
            </ol>

            <Button
                linkAbilitato={true}
                testoBottone={'Rimani aggiornato!'}
                linkTo={'./newsletter'} />
        </div>
    );
};

export default MainSidebar;
