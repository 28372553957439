import React, { useEffect } from "react";

import Navbar from '../Components/Navbar'
import Intestazione from '../Components/Intestazione';
import Container from '../Components/Container';

const Sostienici = () => {
    useEffect(() => {
        document.title = 'Sostienici'
    }, [])

    return (
        <div>
            <Navbar />
            <main className="main-with-margin">
                <Intestazione
                    titolo="Sostienici"
                    sottotitolo="IDEEINSCENA" />


                <Container
                    classname={"container custom-box"}
                    imgSrc={'https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/5per1000.jpg?alt=media&token=9b188232-89c1-4c6a-91d8-b180f10776e2'}
                    titolo={"SOSTIENICI CON IL TUO 5X1000"}
                    testo={`
                        Ogni anno arriva il momento in cui nella dichiarazione dei redditi viene offerta la possibilità di scegliere a chi destinare il proprio 5x1000.
                        Questo semplice gesto, completamente gratuito, è prezioso per consentire a Ideeinscena, associazione di promozione sociale, di continuare a proporre cultura.
                        Se scegli di destinare il tuo 5x1000 a Ideeinscena, ci consentirai di realizzare eventi teatrali e musicali, rassegne letterarie e proposte per il territorio, anche in ambito scolastico; ci consentirai di sviluppare meglio sinergie e collaborazioni, con l’obiettivo di… immaginare e creare il futuro.
                        Basta apporre una firma nell’apposito quadro della dichiarazione, scrivendo anche il nostro codice fiscale 91138010151.
                    `}
                    linkAbilitato={false}
                    testoBottone={'Vai alla pagina'}
                    navigateTo={'/spettacoli'}
                />
            </main>
        </div>
    )
}

export default Sostienici