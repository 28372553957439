import React from 'react';
import { Link } from 'react-router-dom';

const EventoSidebar = ({ spettacoli }) => {
    return (
        <div className="sidebar">
            <h3 className="sidebar-title">Tags</h3>
            <div className="sidebar-item tags">
                <ul>
                    <li><Link to="/spettacoli">Spettacoli</Link></li>
                    <li><Link to="/about">IdeeInScena</Link></li>
                    <li><Link to="/sostienici">Sostienici</Link></li>
                    <li><Link to="/sinergie">Sinergie</Link></li>
                    <li><Link to="/contatti">Contattaci</Link></li>
                </ul>
            </div>
            <h3 className="sidebar-title">Le Commedie</h3>
            <div className="sidebar-item recent-posts">
                {spettacoli.map((spettacolo) => (
                    <div className="post-item clearfix" key={spettacolo.id}>
                        <img src={spettacolo.imgurl} alt={spettacolo.Titolo} />
                        <h4><Link to={`/spettacolo/${spettacolo.id}`}>{spettacolo.Titolo}</Link></h4>
                        <time dateTime={spettacolo.data}>{spettacolo.data}</time>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EventoSidebar;
