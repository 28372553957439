import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const SpettacoloSidebar = () => {
    const [spettacoli, setSpettacoli] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const spettacoliCardCol = collection(db, 'SpettacoliCard');
            const spettacoliCardQuery = query(spettacoliCardCol, where('isBozza', '==', false));
            const spettacoliCardSnapshot = await getDocs(spettacoliCardQuery);

            const spettacoliCardList = spettacoliCardSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setSpettacoli(spettacoliCardList);
        };

        fetchData();
    }, []);

    return (
        <div id="sidebar">
            <h3 className="sidebar-title">Tags</h3>
            <div className="sidebar-item tags">
                <ul>
                    <li><Link to="/spettacoli">Spettacoli</Link></li>
                    <li><Link to="/about">IdeeInScena</Link></li>
                    <li><Link to="/sostienici">Sostienici</Link></li>
                    <li><Link to="/sinergie">Sinergie</Link></li>
                    <li><Link to="/contatti">Contattaci</Link></li>
                </ul>
            </div>

            <h3 className="sidebar-title">Altri Spettacoli</h3>
            <div className="sidebar-item recent-posts">
                {spettacoli.map((spettacolo) => (
                    <div className="post-item clearfix" key={spettacolo.id}>
                        <img src={spettacolo.imgurl} alt={spettacolo.Titolo} />
                        <h4><a href={`../spettacolo/${spettacolo.id}`}>{spettacolo.Titolo}</a></h4>
                        <time dateTime={spettacolo.data}>{spettacolo.data}</time>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SpettacoloSidebar;
