import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import Main from './Pages/Main'
import Chisiamo from './Pages/Chisiamo'
import Spettacoli from './Pages/Spettacoli';
import EventiCulturali from './Pages/EventiCulturali';
import Sinergie from './Pages/Sinergie';
import Sostienici from './Pages/Sostienici';
import Contatti from './Pages/Contatti'
import Spettacolo from './Pages/Spettacolo';
import NotFound from './Pages/NotFound'
import NotAvailable from './Pages/NotAvailable';

import './style.css'
import Evento from './Pages/Evento';
import NewsLetter from './Pages/NewsLetter';
import Policy from './Pages/Policy';
import Workspace from './Pages/Workspace';


const router = createBrowserRouter([
  {
    path: "/", element: <Main />
  }, {
    path: "/about", element: <Chisiamo />
  }, {
    path: "/spettacoli", element: <Spettacoli />
  }, {
    path: "/spettacolo/:id", element: <Spettacolo />
  }, {
    path: "/eventiculturali", element: <EventiCulturali />
  }, {
    path: "/evento/:id", element: <Evento />
  }, {
    path: "/sinergie", element: <Sinergie />
  }, {
    path: "/sostienici", element: <Sostienici />
  }, {
    path: "/contatti", element: <Contatti />
  }, {
    path: "/newsletter", element: <NewsLetter />
  }, {
    path: '/workspace', element: <Workspace />
  }, {
    path: '/policy', element: <Policy />
  }, {
    path: '/nondisponibile', element: <NotAvailable />
  }, {
    path: "*", element: <NotFound />
  }
])

const rootElement = document.getElementById("root")
const root = createRoot(rootElement)

root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
)