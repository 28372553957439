import React, { useEffect } from "react";
import { useMediaQuery } from 'react-responsive';

import Navbar from '../Components/Navbar';
import Intestazione from "../Components/Intestazione";
import Carousel from 'react-bootstrap/Carousel';
import BackupButton from "../Components/BackupButton";
import Footer from '../Components/Footer';

const Chisiamo = () => {
    useEffect(() => {
        document.title = 'Chi Siamo';
    }, []);

    const imageLinks = [
        "https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Fotogruppo%2Ffoto_GRUPPO_ARMADI_5.jpg?alt=media&token=cb329be6-c1db-49f9-9653-f17c4fac8a6b",
        "https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Fotogruppo%2Ffoto_GRUPPO_CAVIALE_4.jpg?alt=media&token=ab707a5e-763c-482c-b853-b14b6a3f3ee3",
        "https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Fotogruppo%2Ffoto_GRUPPO_DIO_3.JPG?alt=media&token=d83365d8-2d74-4dd2-b4cf-527bfde78017",
        "https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Fotogruppo%2Ffoto_GRUPPO_NIPOTI_2.jpg?alt=media&token=7ed164fc-0a5a-4267-b996-9fde10984acc",
        "https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Fotogruppo%2Ffoto_GRUPPO_PECORE_7.jpg?alt=media&token=17a53c25-c20c-4540-adb5-c73cabc286d2",
        "https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Fotogruppo%2Ffoto_GRUPPO_POSTINO_6.jpg?alt=media&token=c775e383-4721-43f3-ae23-54c83e4829bf",
        "https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Fotogruppo%2Ffoto_GRUPPO_SOGNO_1.jpg?alt=media&token=8b8b2f20-ed96-4d07-9814-d9f7543d3c54"
    ];

    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const fontSize = isTablet ? '30px' : '16px';


    return (
        <div>
            <Navbar />
            <header id="header">
                <Intestazione
                    titolo={'CHI SIAMO'}
                    sottotitolo={'IDEEINSCENA'}
                    linkAbilitato={false}
                />
            </header>
            <main id="mainChiSiamo">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <p style={{ fontSize }}>
                                IDEEinSCENA nasce dal desiderio di valorizzare la passione per il teatro.
                                Ma c’è di più. C’è una prospettiva, ovvero quella di stimolare e sostenere la crescita morale, culturale e sociale dell’individuo, attraverso ogni espressione di arte, promuovendone la diffusione in ogni sua forma.
                                Proprio per questo motivo la compagnia sposa lo slogan IMMAGINARE E CREARE IL FUTURO. L’iniziativa nasce nel cuore del territorio brianzolo (riconosciuto come un ambito privilegiato di intraprendenza imprenditoriale e culturale) e ha anche l’ambizione di favorire lo sviluppo, l’educazione e, più ampiamente, il bello, attraverso le attività creative, gli scambi culturali e la valorizzazione dell’inestimabile patrimonio artistico del territorio.
                            </p>
                            <p style={{ fontSize }}>
                                Nel proprio nome IDEEinSCENA richiama la capacità di metterci… la fantasia, partendo dalle doti personali fino al saper lavorare insieme per creare progetti condivisi e non isolate imprese. Il tutto con il valore aggiunto dell’entusiasmo, della motivazione, dell’innovazione. Il teatro di IDEEinSCENA è autentico, parla al pubblico in modo semplice ed efficace, leggero e al tempo stesso pungente. La compagnia propone uno o più testi per ogni stagione, organizza e partecipa a rassegne teatrali. IDEEinSCENA è associazione di promozione sociale, regolarmente iscritta al RUNTS.
                            </p>
                            <br /><br />
                            <p>
                                <a
                                    className="no-underline"
                                    href="https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/statutopersito.pdf?alt=media&token=1a9e96f7-0e15-492a-b6f2-29132743ef87"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <h4>STATUTO DI IDEEINSCENA APS</h4>
                                </a>
                            </p>
                            <p>
                                <a
                                    className="no-underline"
                                    href="https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/1665178159-regolamento-interno_ass-23_02_2021.pdf?alt=media&token=805e7ac0-d97e-4419-a00d-9a1dcc320ee3"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <h4>REGOLAMENTO INTERNO DI IDEEINSCENA APS</h4>
                                </a>
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <Carousel>
                                {imageLinks.map((link, index) => (
                                    <Carousel.Item key={index}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                            alt={`Slide ${index + 1}`}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </main>
            <br /><br />
            {!isTablet && <BackupButton />}
            <Footer />
        </div>
    );
}

export default Chisiamo;
