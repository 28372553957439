import React, { useEffect } from 'react';

import Navbar from '../Components/Navbar'
import Intestazione from '../Components/Intestazione';
import Button from '../Components/Button';
import BackupButton from '../Components/BackupButton';
import Footer from '../Components/Footer';

const Contatti = () => {
    useEffect(() => {
        document.title = 'Contattaci'
    }, [])

    return (
        <div>
            <Navbar />
            <header id='header'>
                <Intestazione
                    titolo="CONTATTACI"
                    sottotitolo="IDEEINSCENA" />
            </header>

            <main id='main' style={{ marginTop: '-90px' }}>
                <section id="contact" className="contact">
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-lg-4">
                                <div className="info">
                                    <div className="address">
                                        <i className="bi bi-geo-alt"></i>
                                        <h4>Posizione:</h4>
                                        <p>Il Centro, via Conciliazione 15, 20832 Desio</p>
                                    </div>
                                    <div className="email">
                                        <i className="bi bi-envelope"></i>
                                        <h4>Email:</h4>
                                        <p>ideeinscenadesio@gmail.com</p>
                                    </div>
                                    <div className="phone">
                                        <i className="bi bi-phone"></i>
                                        <h4>Telefono e Whatsapp:</h4>
                                        <p>351.8364993</p>
                                    </div>
                                    <div className="phone">
                                        <i className="bi bi-phone"></i>
                                        <h4>Codice Fiscale:</h4>
                                        <p>91138010151</p>
                                    </div>

                                    <div className="phone">
                                        <Button
                                            linkAbilitato={true}
                                            testoBottone={'Iscriviti alla newsletter'}
                                            linkTo={'/newsletter'} />
                                    </div>
                                </div>
                                <br />
                                <div className="mt-4">
                                    <h3>Siamo affiliati a FITA e GATAL</h3>
                                    <h3>Siamo iscritti al RUNTS</h3>
                                </div>
                            </div>
                            <div className="col-lg-8 mt-5 mt-lg-0">
                                <iframe
                                    src="https://docs.google.com/forms/d/e/1FAIpQLSdLpsK6dWci-2t675JUCKALujQQ7yyqGaN6u1vmzIUWx99yIw/viewform?embedded=true"
                                    frameBorder="0" marginHeight="0" marginWidth="0"
                                    style={{ width: '100%', height: '880px' }}
                                    title="Modulo di contatto IdeeInScena"
                                >
                                    Caricamento…
                                </iframe>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <BackupButton />
            <Footer />
        </div>
    )
}

export default Contatti;
