import React, { useEffect } from "react";
import { useMediaQuery } from 'react-responsive';


import Navbar from '../Components/Navbar'
import Footer from "../Components/Footer";
import Intestazione from "../Components/Intestazione";
import BackupButton from "../Components/BackupButton";
import Carousel from "../Components/Carousel";

const Spettacoli = () => {
    useEffect(() => {
        document.title = 'Spettacoli'
    }, [])

    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    return (
        <div>
            <Navbar />
            <header id="header">
                <Intestazione
                    titolo="SPETTACOLI"
                    sottotitolo="IDEEINSCENA"
                    testo1='
                            La principale attività di Ideeinscena è quella teatrale. 
                            La compagnia è impegnata nella produzione di commedie che spaziano nel vasto panorama degli autori comici e brillanti.
                        '
                    testo2=''
                    testo3='Tra gli autori messi in scena spiccano Eduardo De Filippo, Eduardo Scarpetta, Scarnucci e Tarabusi, Derek Benfield, Denny Arrichiello'
                    linkAbilitato={false}
                />
            </header>
            <main>
                <section>
                    <Carousel />
                </section>
            </main>
            {!isTablet && <BackupButton />}
            <Footer />
        </div>
    )
}

export default Spettacoli